@for $num from 1 to 5 {
  .text-spaced-#{$num} {
    letter-spacing: rem-calc($num);
  }
}

.text-light {
  font-weight: 300;
}

small {
  line-height: rem-calc(12);
}

.text-bold {
  font-weight: 500;
}

.text-serif {
  font-family: 'rufina', 'Century Gothic', serif;
}

.text-sans {
  font-family: "apex", Helvetica, Roboto, Arial, sans-serif;
}

.text-center {
  text-align: center;
}

.headline1 {
  font-size: rem-calc(48);
  line-height: 1;
  font-weight: 500;
}

.oversized-title {
  font-size: clamp(rem-calc(50),16vw, rem-calc(80));
  letter-spacing: -0.5vw;
  line-height: 0.8;
  font-weight: 500;
  margin-bottom: rem-calc(32);
  font-weight: bold;
}

@each $num in (20, 30, 40, 50, 60, 70, 80, 90, 100) {
  .f-#{$num} {
    font-size: percentage($num / 100);
    line-height: 1;
  }
}

.subheading {
  line-height: 1.3;
  @extend .c-gray-70;
  margin-bottom: rem-calc(48);
}

label, select, input {
  font-weight: 300 !important;
}