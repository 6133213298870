@font-face {
  font-family: "iconfonts";
  src: url('/assets/fonts/iconfonts.eot');
  src: url('/assets/fonts/iconfonts.eot?#iefix') format('eot'),
    url('/assets/fonts/iconfonts.woff2') format('woff2'),
    url('/assets/fonts/iconfonts.woff') format('woff'),
    url('/assets/fonts/iconfonts.ttf') format('truetype'),
    url('/assets/fonts/iconfonts.svg#iconfonts') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon-styles {
  font-family: "iconfonts";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

   @if $filename==arrow-down {
      $char: "\E001";
    }

     @if $filename==arrow-up {
      $char: "\E002";
    }

     @if $filename==arrow {
      $char: "\E003";
    }

     @if $filename==calendar {
      $char: "\E004";
    }

     @if $filename==calendar2 {
      $char: "\E005";
    }

     @if $filename==close {
      $char: "\E006";
    }

     @if $filename==dot-icon {
      $char: "\E007";
    }

     @if $filename==facebook {
      $char: "\E008";
    }

     @if $filename==instagram {
      $char: "\E009";
    }

     @if $filename==left-arrow {
      $char: "\E00A";
    }

     @if $filename==mail {
      $char: "\E00B";
    }

     @if $filename==minus {
      $char: "\E00C";
    }

     @if $filename==pinterest {
      $char: "\E00D";
    }

     @if $filename==play {
      $char: "\E00E";
    }

     @if $filename==plus {
      $char: "\E00F";
    }

     @if $filename==right-arrow {
      $char: "\E010";
    }

     @if $filename==search {
      $char: "\E011";
    }

     @if $filename==twitter {
      $char: "\E012";
    }

     @if $filename==youtube {
      $char: "\E013";
    }

    @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    }

    @else {
      @include icon-styles;
    }

    content: icon-char($filename);
  }
}

.icon-arrow-down {
    @include icon(arrow-down);
  }

  .icon-arrow-up {
    @include icon(arrow-up);
  }

  .icon-arrow {
    @include icon(arrow);
  }

  .icon-calendar {
    @include icon(calendar);
  }

  .icon-calendar2 {
    @include icon(calendar2);
  }

  .icon-close {
    @include icon(close);
  }

  .icon-dot-icon {
    @include icon(dot-icon);
  }

  .icon-facebook {
    @include icon(facebook);
  }

  .icon-instagram {
    @include icon(instagram);
  }

  .icon-left-arrow {
    @include icon(left-arrow);
  }

  .icon-mail {
    @include icon(mail);
  }

  .icon-minus {
    @include icon(minus);
  }

  .icon-pinterest {
    @include icon(pinterest);
  }

  .icon-play {
    @include icon(play);
  }

  .icon-plus {
    @include icon(plus);
  }

  .icon-right-arrow {
    @include icon(right-arrow);
  }

  .icon-search {
    @include icon(search);
  }

  .icon-twitter {
    @include icon(twitter);
  }

  .icon-youtube {
    @include icon(youtube);
  }

  