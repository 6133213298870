@import "base/color";
@import "settings";
@import "foundation";
@import "components/iconfonts";
@import "base/icon-sizes";

// Grids
@include foundation-xy-grid-classes;


// Global
@include foundation-global-styles;
@include foundation-typography;
@include foundation-forms;

// Generic Components
@include foundation-button;

// Helpers
@include foundation-visibility-classes;
@include foundation-flex-classes;
@include foundation-prototype-classes;

@import "base/typography";

// @include foundation-everything($prototype: true);

html,
body {
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: hidden;
}


* {
  outline: none;
}

.preorder-preview-image {
  margin: auto;
  max-height: rem-calc(400);

  @include breakpoint(medium up) {
    max-height: rem-calc(500);
  }
}

.preorder-options {
  width: 100%;

  img {
    max-height: rem-calc(200);
    display: inline-block;
  }

  background-color: rgba(#1c2349, 0.95);

  label {
    color: $white;
  }
}

.selected-option {
  background-color: rgba($white, 0.1);
  border: rem-calc(1) solid rgba($white, 0.2);
  box-shadow: rem-calc(8) rem-calc(8) rem-calc(20) rgba($black, 0.05);
  border-radius: rem-calc(8);
  transition: transform 0.3s ease-out;
  transform: scale(1.1);
  cursor: pointer;

  h6 {
    font-weight: 500;
  }
}

.option {
  padding: rem-calc(20);
  user-select: none;
  cursor: pointer;
  transition: transform 0.3s ease-out;

  @include breakpoint(small only) {
    margin-left: rem-calc(16);
    margin-right: rem-calc(16);
  }

  &.singles {
    img {
      max-width: 50%;

      @include breakpoint(medium up) {
        max-width: 60%;
      }
    }
  }
}

.small-centered {
  margin: auto auto;
}

.medium-centered {
  @include breakpoint(medium up) {
    margin: auto auto;
  }
}

.large-centered {
  @include breakpoint(medium up) {
    margin: auto auto;
  }
}

.preorder-section {
  width: 100%;
}

// .form-column {
//   @extend .cell;
//   margin: 0 !important;
//   flex: 1;
// }

.form-row {
  @extend .cell;
  @extend .grid-x;
  justify-content: space-between;

  .form-column {
    width: 100%;

    @include breakpoint(large up) {
      max-width: 49%;
    }

    // &:last-child {

    // }
  }
}

.button {
  background-color: red;
  padding: rem-calc(12) rem-calc(24);
  border-radius: rem-calc(4);
  font-weight: bold;
  color: $white;
  cursor: pointer !important;
  user-select: none;
  letter-spacing: rem-calc(1);

  &:hover {
    background-color: darken(red, 10%)
  }

  &:active,
  &:focus {
    background-color: darken(red, 10%)
  }
}

.button.alt {
  color: red;
  border: rem-calc(1) solid red;
  background-color: transparent;
}

.error-message {
  color: red;
  // padding-top: rem-calc(30);
  font-style: italic;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.error-message.show {
  visibility: visible;
  opacity: 1
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  font-weight: 300;
  border-radius: rem-calc(4);
}

.group-radio {
  padding: rem-calc(12) rem-calc(24);
  border: rem-calc(1) solid rgba(0, 0, 0, 0.2);
  margin-bottom: rem-calc(16);
  border-radius: rem-calc(4);
}

.form-quantity {
  margin: auto;
  padding-top: rem-calc(24);

  &__input {
    width: rem-calc(60);
    height: rem-calc(60);
    font-size: rem-calc(24);
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    margin-left: rem-calc(8);
    margin-right: rem-calc(8);
    appearance: none;
    -moz-appearance: textfield;
    border: rem-calc(1) solid rgba(red, 0.6);

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -moz-appearance: textfield;
      margin: 0;
      -webkit-appearance: none;
    }
  }

  &__increase,
  &__reduce {
    // background-color: red;
    width: rem-calc(24);
    height: rem-calc(24);
    border-radius: rem-calc(24);
    display: inline-block;
    color: rgba(red, 0.6);
    // padding: rem-calc(8);
    cursor: pointer;
    transition: color 0.3s ease-out, transform 0.2s ease-out;

    &:hover {
      color: red;
      transform: scale(1.2);
    }
  }
}

.parsley-errors-list {
  margin-left: 0;
  margin-top: rem-calc(-8);

  li {
    list-style-type: none;
    font-size: 80%;
    color: red;
  }
}

.form-section {
  align-self: center;
}

.bottom-stroke {
  margin-bottom: rem-calc(8);
  // padding-bottom: rem-calc(8);
  border-bottom: rem-calc(1) dashed rgba($black, 0.1);
}

.small-number {
  font-size: 60%;
}

.video {
  width: 100%;
  position: relative;
  padding-top: 56.4%;

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.quote {
  @extend h3;
  font-style: italic;
}

.chapters {
  background-color: rgba(#c9baba, 0.5);
  overflow: hidden;
}

.pad-mobile {
  @include breakpoint(small only) {
    padding-left: rem-calc(24);
    padding-right: rem-calc(24);
  }
}

.text-light {
  font-weight: 300;
}

.chapters-preview {
  text-align: center;
}

.chapter {
  width: 9.4%;
  padding: rem-calc(12);
  background-color: red;
  height: rem-calc(500);
  text-align: center;
  color: $white;
  margin: rem-calc(1);
  display: inline-block;
  user-select: none;

  @include breakpoint(medium up) {
    width: rem-calc(60);
    height: rem-calc(800);
  }

  &__number {
    padding-bottom: rem-calc(16);
    margin-bottom: rem-calc(24);
    border-bottom: rem-calc(1) solid $white;
    display: inline-block;
    font-size: rem-calc(12);
    font-weight: 500;

    @include breakpoint(medium up) {
      margin-bottom: rem-calc(48);
      font-size: rem-calc(18);

    }
  }

  &__title {
    transform: rotate(90deg);
    white-space: nowrap;
    @extend .text-serif;
    @extend h3;

    @include breakpoint(small only) {
      font-size: rem-calc(18);
    }
  }
}

.book-and-preorder {
  visibility: hidden;
  animation: animate-preorder 1s 15s ease-out forwards;
}

@keyframes animate-preorder {
  0% {
    visibility: hidden;
    transform: translateY(20%);
    opacity: 0;
  }

  100% {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}

.opener__wrapper {
  min-height: 100vh;
}

.opener,
.opener span {
  font-size: 16vw;
  letter-spacing: rem-calc(-1);
  line-height: 0.8;

  @include breakpoint(medium up) {
    font-size: 10vw;
    letter-spacing: rem-calc(-2);
  }
}

.preorder-large {
  font-size: rem-calc(18);
  animation: animate-preorder 1s 16s ease-out forwards;
  visibility: hidden;

  @include breakpoint(medium up) {
    font-size: rem-calc(20);
  }
}

.preorder-main-date {
  visibility: hidden;
  animation: animate-preorder 1s 17s ease-out forwards;
}

ul.slick-dots {
  margin: rem-calc(-24) auto;

  li {
    width: rem-calc(40);
    height: rem-calc(3);
    overflow: hidden;

    button {
      display: block;

      &::before {
        content: '';
        display: inline-block;
        width: rem-calc(40);
        height: rem-calc(3);
        background-color: $black;
        border-radius: rem-calc(4);
      }
    }
  }
}

.sellers {
  img {
    max-height: rem-calc(32);
    padding-right: rem-calc(48);
  }
}

.the-author {
  background-image: url("/assets/img/Pastor-Mo-background.jpg");
  background-size: contain;
  background-repeat: no-repeat;

  @include breakpoint(small only) {
    background-size: 160%;
  }

  @include breakpoint(medium only) {
    background-size: 150%;
    background-position: 30% 0%;
  }

  @include breakpoint(large up) {
    background-size: cover;
  }

  &__bio {
    font-weight: 300;
    max-width: rem-calc(500);
    background-color: $white;
    padding: rem-calc(60);

    @include breakpoint(small only) {
      margin-top: rem-calc(190);
    }

    @include breakpoint(large only) {
      margin-top: 20%;
    }
  }
}

.form-back {
  transition: transform 0.3s ease-out;
  display: inline-block;
  padding: rem-calc(16);
  cursor: pointer;
  ;

  &:hover {
    transform: scale(1.2);
  }
}

.preorder-form {
  background-color: rgba(#1c2349, 0.02);
  display: flex;
  width: 100%;

  @include breakpoint(small only) {
    // padding-left: rem-calc(20);
  }
}

.overlay {
  width: 95vw;
  height: 95vh;
  position: fixed;
  margin: auto auto;
  background-color: red;
  z-index: 200;
  top: 2.5vh;
  left: 2.5vw;
  border-radius: rem-calc(8);
  box-shadow: rem-calc(10) rem-calc(10) rem-calc(30) rgba($black, 0.3);
  overflow: hidden;
  visibility: hidden;
  animation: fadeIn 0.6s 3s forwards ease-out;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 10% center;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 45%;
  }

  .official {
    width: 45vw;
    margin-bottom: rem-calc(20);

    @include breakpoint(medium up) {
      width: 40vw;
    }
  }

  &__close {
    position: absolute;
    right: rem-calc(20);
    top: rem-calc(20);
    z-index: 5;
    background-color: $white;
    border-radius: 50%;
    width: rem-calc(30);
    height: rem-calc(30);
    text-align: center;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer // @include breakpoint(medium up) {
    //   width: rem-calc(40);
    //   height: rem-calc(40);
    // }
  }

}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.hide-overlay {
  display: none;
}

.download-options {
  li {
    list-style-type: none;
    a {
      padding: rem-calc(16);
      margin: rem-calc(4);
      border-radius: rem-calc(8);
      border: rem-calc(1) solid rgba(gray, 0.2);
      user-select: none;
      transition: transform 0.3s ease-out;
      cursor: pointer;
      display: flex;
      
      &:hover {
        transform: scale(1.05);
        background-color: red;
        color: $white;

        * {
          color: $white;
        }
      }
    }
  }
}