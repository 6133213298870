$black: #000000;
$white: #ffffff;
$colors: (
  "black": $black,
  "white": $white,
  "red": red,
  "gray-1": rgba($black, 0.01),
  "gray-3": rgba($black, 0.03),
  "gray-5": rgba($black, 0.05),
  "gray-10": rgba($black, 0.1),
  "gray-20": rgba($black, 0.2),
  "gray-30": rgba($black, 0.3),
  "gray-40": rgba($black, 0.4),
  "gray-50": rgba($black, 0.5),
  "gray-60": rgba($black, 0.6),
  "gray-70": rgba($black, 0.7),
  "gray-80": rgba($black, 0.8),
);

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }

  .c-#{$color} {
    color: $value;
  }
}
